import React, {useState} from "react";
import './Home.css';
import CardTemplate from "./CardTemplate";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Gallery = ()=>{

    const [displayCards, setDisplayCards] = useState();

    const setGallery = () => {
        return(
            setDisplayCards(["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg", "12.jpg", "13.jpg"])
        )
    }

    const ArrowAndText = <div><p className="ArrowAndText">Zobacz Galerię<br></br><KeyboardArrowDownIcon sx={{ fontSize: 100 , color: "black" }} cursor="pointer" onClick={()=>setGallery()}/></p></div>

    return(
        <AnimationOnScroll animateIn="animate__backInRight">
        <div className="galleryBackground">
            {displayCards ? displayCards.map((displayCards)=><div className="card" key={displayCards}><CardTemplate path={displayCards}/></div>) : ArrowAndText}
        </div>
        </AnimationOnScroll>
    )
}

export default Gallery;