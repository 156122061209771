import React, { useState } from "react";
import './Home.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AnimationOnScroll } from "react-animation-on-scroll";
const Offer = ()=>{

  const [activeList, setActiveList] = useState({
    konsultacje:false,
    mezoterapia_iglowa:false,
    stymulatory_tkankowe:false,
    mezoterapia_mikroiglowa:false,
    mezoterapia_mikroiglowa_RF:false,
    falaRadiowa:false,
    oczyszczanieWodorowe:false,
    skinLift:false,
    depilacjaLaserowa:false,
    peelingiKwasowe:false,
    kosmetyka_biala:false,
    stylizacjaRzes:false,
    stylizacjaBrwiIRzes:false,
    laminacjaBrwiIRzes:false,
    depilacjaWoskiemLycon:false,
    zabiegiNaCialo:false,
    pielegnacjaDloniIStop:false
  })

  const checkAndSetActiveList =(clickedList)=> {
    setActiveList(prevState=> (
      {
        konsultacje:false,
        mezoterapia_iglowa:false,
        stymulatory_tkankowe:false,
        mezoterapia_mikroiglowa:false,
        mezoterapia_mikroiglowa_RF:false,
        falaRadiowa:false,
        oczyszczanieWodorowe:false,
        skinLift:false,
        depilacjaLaserowa:false,
        peelingiKwasowe:false,
        kosmetyka_biala:false,
        stylizacjaRzes:false,
        stylizacjaBrwiIRzes:false,
        laminacjaBrwiIRzes:false,
        depilacjaWoskiemLycon:false,
        zabiegiNaCialo:false,
        pielegnacjaDloniIStop:false,
      [clickedList] : true
    }))
  }

  console.log(activeList)

  const kosmetologia = {
    konsultacje: <ul>
    <li><div className="header">Konsultacja kosmetologiczna</div><div className="price">GRATIS</div></li>
    <li><div className="header">Kontrola po zabiegu</div><div className="price">GRATIS</div></li>
    
  </ul>,
  mezoterapia_iglowa: <ul>
  <li><div className="header">Twarz</div><div className="price">350 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">400 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">450 zł</div></li>
  <li><div className="header">Skóry głowy</div><div className="price">750 zł</div></li>
  <li><div className="header">Mezoterapia igłowa twarzy (ujędrnienie, poprawa owalu twarzy)</div><div className="price">350 zł</div></li>
  <li><div className="header">Okolica oczu</div><div className="price">350 zł</div></li>
  <li><div className="header">Ciało (rozstępy, ujędrnienie skóry, cellulit</div><div className="price">350 zł</div></li>
  
  
</ul>,
stymulatory_tkankowe: <ul>

<ul>OKOLICE OKA
<li><div className="header">Rozświetlenie i redukcja zasinień</div><div className="price">490 zł</div></li>
<li><div className="header">Nawilżenie i odżywienie</div><div className="price">490 zł</div></li>
<li><div className="header">Rewitalizacja i redukcja zmarszczek</div><div className="price">550 zł</div></li>
  </ul>
<ul>TWARZ
<li><div className="header">Ujędrnienie, poprawa owalu twarzy</div><div className="price">990 zł</div></li>
<li><div className="header">Odżywienie i nawilżenie</div><div className="price">750 zł</div></li>
  </ul>

</ul>,
  mezoterapia_mikroiglowa: <ul>
  <li><div className="header">Twarz </div><div className="price">300 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">350 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt </div><div className="price">390 zł</div></li>
  <li><div className="header">Terapia blizn /rozstępy</div><div className="price">250 - 350 zł*</div></li>
  <li><div className="header">Retix C Retibooster</div><div className="price">450 zł</div></li>
  <li><div className="header">Biorepeel </div><div className="price">350 zł</div></li>
  <li><div className="header">*Cena uzależniona jest od obszaru zabiegowego i użytych preparatów </div></li>
</ul>,
  mezoterapia_mikroiglowa_RF: <ul>
  <li><div className="header">Twarz </div><div className="price">490 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">550 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">700 zł</div></li>
  <li><div className="header">Oczy</div><div className="price">350 zł</div></li>
  <li><div className="header">Policzki (blizny potrądzikowe)</div><div className="price">390 zł</div></li>
  <li><div className="header">Brzuch (rozstępy, ujędrnienie skóry)	</div><div className="price">590 zł</div></li>
  <li><div className="header">Inne części ciała cenę ustalamy na konsultacji</div></li>
</ul>,
  falaRadiowa: <ul>
  <li><div className="header">Twarz	</div><div className="price">250 zł</div></li>
  <li><div className="header">Szyja</div><div className="price">200 zł</div></li>
  <li><div className="header">Dekolt	</div><div className="price">250 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">350 zł</div></li>
  <li><div className="header">Oczy </div><div className="price">190 zł</div></li>
  <li><div className="header">Biorepeel </div><div className="price">350 zł</div></li>
</ul>,
  oczyszczanieWodorowe: <ul>
  <li><div className="header">Twarz</div><div className="price">190 - 250 zł*</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">220 - 260 zł*</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">250 - 300 zł*</div></li>
  <li><div className="header">Jako dodatek do innego zabiegu</div><div className="price">70 zł</div></li>
  <li>{/*<div className="header">Zabieg rozszerzony twarz</div><div className="price">230 zł</div></li>
  <li><div className="header">Zabieg rozszerzony twarz + szyja + dekolt</div><div className="price">260 zł</div></li>
  <li><div className="header">Zabieg de lux - silne odmłodzenie i odżywienie skóry twarz</div><div className="price">299 zł</div></li>
  <li><div className="header">Zabieg de lux – twarz + szyja + dekolt	</div><div className="price">350 zł</div></li>
  <li><div className="header">Jako dodatek do innego zabiegu </div><div className="price">70 zł</div>*/}</li>
  <div className="otherInfo">*Cena uzależniona jest od obszaru zabiegowego i użytych preparatów</div>
  <div className="otherInfo">{/*Seaweex daje możliwości łączyć podczas jednego zabiegu oczyszczanie ciepłym wodorem, hydrabrazję kwasową, infuzję tlenową, falę radiową bipolarną, EMS aktywacja komórek mięśniowych, mikroprądy, głowicę rozgrzewająco – chłodzącą.*/}</div>
</ul>,
skinLift: <ul>
<ul>BIOSTYMULACJA I ODNOWA TKANEK NA POZIOMIE KOMÓRKOWYM
  <li><div className="header">Twarz</div><div className="price">250 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">300 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">350 zł</div></li>
  <li><div className="header">Ramiona (pelikany)</div><div className="price">200 zł</div></li>
  <li><div className="header">Biust</div><div className="price">200 zł</div></li>
  <li><div className="header">Brzuch</div><div className="price">250 zł</div></li>
  <li><div className="header">Pośladki + uda</div><div className="price">290 zł</div></li>
  </ul>
  <ul>DEPILACJA LASEROWA
  <li><div className="header">Wąsik</div><div className="price">160 zł</div></li>
  <li><div className="header">Twarz – wąsik + broda + policzki</div><div className="price">210 zł</div></li>
  <li><div className="header">Bikini – pachwiny </div><div className="price">250 zł</div></li>
  <li><div className="header">Bikini – głębokie</div><div className="price">350 zł</div></li>
  <li><div className="header">Bikini – brazylijskie</div><div className="price">390 zł</div></li>
  <li><div className="header">Pachy</div><div className="price">230 zł</div></li>
  <li><div className="header">Ręce - przedramiona</div><div className="price">250 zł</div></li>
  <li><div className="header">Ręce – całe</div><div className="price">290 zł</div></li>
  <li><div className="header">Nogi – łydki</div><div className="price">290 zł</div></li>
  <li><div className="header">Nogi – uda</div><div className="price">290 zł</div></li>
  <li><div className="header">Nogi – całość </div><div className="price">530 zł</div></li>
  <li><div className="header">Plecy </div><div className="price">390 zł</div></li>
  <li><div className="header">Klatka piersiowa </div><div className="price">390 zł</div></li>
  <div className="otherInfo">Obszary niewymienione w cenniku wyceniamy indywidualnie </div>
  <div className="otherInfo">Przy serii zabiegowej lub łączeniu kilku partii ciała naliczamy rabaty</div>
  </ul>
  <ul>FOTOODMŁADZANIE
  <li><div className="header">Twarz</div><div className="price">350 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">390 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">450 zł</div></li>
  </ul>

  <ul>PEELING WĘGLOWY BLACK DOLL
  <li><div className="header">Twarz</div><div className="price">260 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">290 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">350 zł</div></li>
  <li><div className="header">Twarz + Gen Factor Monokwasy</div><div className="price">300 zł</div></li>
  <li><div className="header">Twarz + Gen Factor Monokwasy i Żele</div><div className="price">350 zł</div></li>
  <li><div className="header">Plecy</div><div className="price">350 zł</div></li>
  <li><div className="header">Klatka piersiowa</div><div className="price">350 zł</div></li>
  </ul>
</ul>,
  peelingiKwasowe: <ul>
  <li><div className="header">Monduniq Kwas Migdałowy 40%</div><div className="price">150 zł</div></li>
  <li><div className="header">Retix C Retiskin Lab</div><div className="price">149 zł</div></li>
  <div className="otherInfo">
  Kwas azelainowym 14% w połączeniu z kwasem salicylowym 2%<br/>
Kwas cytrynowy 30% w połączeniu z kwasem migdałowym 20%<br/>
Kwas glikolowy 35%<br/>
Kwas mlekowy 36%<br/>
Kwas migdałowy 40%<br/><br/>

Mieszanki kwasowe są dobierane indywidualnie do typu cery i efektu jaki mamy na skórze wypracować. Podczas zabiegu możemy max zastosować 3 rodzaje kwasu.

  </div>
  <li><div className="header">Retix C Peptiglow </div><div className="price">149 zł</div></li>
  <div className="otherinfo">Koktail kwasowy (kwas mlekowy, glikolowy, cytrynowy, ferulowy,
wit. C i tripeptydy)
  </div>
  <li><div className="header">Retix C Revive Tc3</div><div className="price">250 zł</div></li>
  <div className="otherInfo">(Kwas TCA 12%, kwas kojowy 4%, kwas migdałowy 4%, wit C 20%, 
kwas ferulowy, kwas hialurnonowy)
  </div>
  <li><div className="header">Gen Factor PH  (całoroczne monokwasy terapeutyczne)</div><div className="price">od 159 do 199 zł</div></li>
  <div className="otherInfo">Kwas Caffeic – kwas kawowy<br/>
Kwas Humic – kwas huminowy<br/>
Kwas Nicotonic – kwas nikotynowy<br/>
Kwas Rosmarinic – kwas rozmarynowy<br/>
Kwas Protocatechuic – kwas protokatechowy<br/>
Kwas Madecassic – kwas madekasowy<br/>
Kwas Asiatica Complex – kompleks kwasowy z wąkroty azjatyckiej<br/><br/>

Mieszanki kwasowe są dobierane indywidualnie do typu cery i efektu jaki mamy na skórze wypracować. Podczas zabiegu możemy max zastosować 3 rodzaje kwasu.
</div>
<li><div className="header">BioRePeel Cl3 </div><div className="price">280 zł</div></li>

</ul>,
kosmetyka_biala: <ul>
<li><div className="header">Est Gen - trądzik różowaty, AZS</div><div className="price">220 zł</div></li>
<li><div className="header">Est Gen - trądzik</div><div className="price">220 zł</div></li>
<li><div className="header">Est Gen - anty age</div><div className="price">250 zł</div></li>
<li><div className="header">Gen Factor PH Monokwawsy </div><div className="price">230 zł</div></li>
<li><div className="header">Gen Factor PH Monokwawsy + żele</div><div className="price">260 - 320 zł*</div></li>
<li><div className="header">Gen Factor PH Retinoid System</div><div className="price">350 zł</div></li>
<li><div className="header">ILCSI - organiczny zabieg paprykowy</div><div className="price">220 zł</div></li>
<li><div className="header">MALU WILZ - zabieg anty age z masażem </div><div className="price">260 zł</div></li>
<li><div className="header">AGE LOC - zabieg bankietowy</div><div className="price">199 zł</div></li>
<li><div className="header">BioRePeel Cl3</div><div className="price">290 zł</div></li>
<li><div className="header">Retix C Retibooster</div><div className="price">360 zł</div></li>
<li><div className="header">Masaż KOBIDO</div><div className="price">160 zł</div></li>
<li><div className="header">Masaż KOBIDO + maska</div><div className="price">200 zł</div></li>
<li><div className="header">Masaż KOBIDO + zabieg na twarz</div><div className="price">290 zł</div></li>
<li><div className="header">Masaż relaksująco-liftingujący</div><div className="price">160 zł</div></li>
<div className="otherInfo">*Cena uzależniona jest od rodzaju i ilości użytych preparatów</div>
</ul>,
stylizacjaRzes: <ul>
<li><div className="header">Rzęsy 1:1 założenie </div><div className="price">180 zł</div></li>
<li><div className="header">Rzęsy 1:1 uzupełnienie (do 4 tyg.)</div><div className="price">155 zł</div></li>
<li><div className="header">Rzęsy 2-3d założenie</div><div className="price">220 zł</div></li>
<li><div className="header">Rzęsy 2-3d uzupełnienie (do 4 tyg.)</div><div className="price">165 zł</div></li>
<li><div className="header">Rzęsy 4-6d założenie</div><div className="price">250 zł</div></li>
<li><div className="header">Rzęsy 4-6d uzupełnienie (do 4 tyg.)</div><div className="price">175 zł</div></li>
<div className="otherInfo">Rabat -10% na stylizacje wykonane przez Brygidę i Justynę</div>
  <li>{/*<div className="header">Rzęsy 7-10d założenie</div><div className="price">280 zł</div>*/}</li>
<li>{/*<div className="header">Rzęsy 7-10d uzupełnienie</div><div className="price">od 220 do 260 zł</div>*/}</li>
</ul>,
stylizacjaBrwiIRzes: <ul>
<li><div className="header">Henna brwi + regulacja	</div><div className="price">55 zł</div></li>
<li><div className="header">Henna brwi i rzęs + regulacja </div><div className="price">65 zł</div></li>
<li><div className="header">Henna brwi lub rzęs</div><div className="price">35 zł</div></li>
<li><div className="header">Henna pudrowa brwi + regulacja	</div><div className="price">100 zł</div></li>
<li><div className="header">Henna pudrowa brwi i rzęs + regulacja </div><div className="price">110 zł</div></li>
<li><div className="header">Koloryzacja brwi farbką + regulacja </div><div className="price">70 zł</div></li>
<li><div className="header">Koloryzacja brwi i rzęs farbką + regulacja</div><div className="price">80 zł</div></li>
<li><div className="header">Regulacja brwi</div><div className="price">35 zł</div></li>
</ul>,
laminacjaBrwiIRzes: <ul>
<li><div className="header">Laminacja brwi + regulacja </div><div className="price">120 zł</div></li>
<li><div className="header">Laminacja brwi + farbka + regulacja</div><div className="price">140 zł</div></li>
<li><div className="header">Laminacja rzęs + farbka</div><div className="price">140 zł</div></li>
<li><div className="header">Laminacja rzęs</div><div className="price">120 zł</div></li>
<li><div className="header">Pakiet laminacja brwi i rzęs</div><div className="price">240 zł</div></li>
</ul>,
depilacjaWoskiemLycon: <ul>
<li><div className="header">Wąsik</div><div className="price">35 zł</div></li>
<li><div className="header">Twarz</div><div className="price">50 zł</div></li>
<li><div className="header">Ręce</div><div className="price">65 zł</div></li>
<li><div className="header">Pachy</div><div className="price">50 zł</div></li>
<li><div className="header">Nogi łydki </div><div className="price">65 zł</div></li>
<li><div className="header">Nogi uda</div><div className="price">65 zł</div></li>
<li><div className="header">Nogi całe</div><div className="price">90 zł</div></li>
<li><div className="header">Bikini pachwiny</div><div className="price">65 zł</div></li>
<li><div className="header">Bikini pełne</div><div className="price">150 zł</div></li>
<li><div className="header">Klatka piersiowa</div><div className="price">60 zł</div></li>
<li><div className="header">Plecy całe</div><div className="price">90 zł</div></li>
</ul>,
zabiegiNaCialo: <ul>

<li><div className="header">Masaż próżniowy + RF nogi + brzuch</div><div className="price">150 zł</div></li>
<li><div className="header">Fala Radiowa (RF) brzuch</div><div className="price">120 zł</div></li>
<li><div className="header">Fala Radiowa (RF) uda + pośladki</div><div className="price">160 zł</div></li>
<li><div className="header">Fala Radiowa (RF) ramiona</div><div className="price">130 zł</div></li>
<li><div className="header">Liposukcja Kawitacyjna brzuch	</div><div className="price">150 zł</div></li>
<li><div className="header">Liposukcja Kawitacyjna uda + pośladki</div><div className="price">170 zł</div></li>
<li><div className="header">Termogenique z masażem manualnym (zabieg ujędrniająco-wyszczuplający)</div><div className="price">230 zł</div></li>
<li><div className="header">Termogenique z aparaturą (zabiegi ujędrniająco-wyszczuplający)</div><div className="price">260 zł</div></li>
<li><div className="header">Skin Lift 448kHZ ramiona</div><div className="price">200 zł</div></li>
<li><div className="header">Skin Lift 448kHZ biust</div><div className="price">200 zł</div></li>
<li><div className="header">Skin Lift 448kHZ brzuch</div><div className="price">250 zł</div></li>
<li><div className="header">Skin Lift 448kHZ uda + pośladki</div><div className="price">290 zł</div></li>
<li><div className="header">Bandaże Arosha</div><div className="price">200 zł</div></li>
<div className="otherInfo">Ceny promocyjne pakietów zabiegowych na ciało są naliczane indywidualnie po konsultacji
</div>

</ul>,
  pielegnacjaDloniIStop: <ul>
  <ul>STYLIZACJA PAZNOKCJI
  <li><div className="header">Hybryda</div><div className="price">110 zł</div></li>
  <li><div className="header">Hybryda z nadbudową	</div><div className="price">120 zł</div></li>
  <li><div className="header">żel na naturalnej płytce</div><div className="price">130 zł</div></li>
  <li><div className="header">żel przedłużenie</div><div className="price">150 zł</div></li>
  <li><div className="header">Rekonstrukcja - naprawa 1 paznokcia</div><div className="price">15 zł</div></li> 
  <li><div className="header">Manicure klasyczny </div><div className="price">70 zł</div></li>
  <li><div className="header">Manicure klasyczny + lakier</div><div className="price">85 zł</div></li>
  <li><div className="header">Ściągnięcie stylizacji + manicure</div><div className="price">70 zł</div></li>
  <li><div className="header"><b>Dodatkowo płatne:</b></div><div className="price"></div></li>
  <li><div className="header">Babyboomer</div><div className="price">+10 zł</div></li>
  <li><div className="header">French</div><div className="price">+15 zł</div></li>     
  <li><div className="header">Zdobienia</div><div className="price">+20 zł</div></li>   
  </ul>
  <ul>PEDICURE
  <li><div className="header">Hybryda na stopach</div><div className="price">100 zł</div></li>  
  <li><div className="header">Lakier klasyczny na stopach</div><div className="price">90 zł</div></li>  
  <li><div className="header">Pedicure</div><div className="price">130 zł</div></li>  
  <li><div className="header">Pedicure + hybryda</div><div className="price">160 zł</div></li>  
  <li><div className="header">Pedicure + lakier	</div><div className="price">145 zł</div></li>  
  <li><div className="header">Ściągnięcie stylizacji + odżywka/lakier</div><div className="price">70 zł</div></li>     
   
  </ul>  
</ul>
  }
    return(
      <AnimationOnScroll animateIn="animate__backInLeft">
      <div className="offerBackground">
        <div className="offerMenu">
        <div className="offerBlocks">
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('konsultacje')}>Konsultacje</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('stymulatory_tkankowe')}>Stymulatory Tkankowe</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('mezoterapia_iglowa')}>Mezoterapia Igłowa</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('mezoterapia_mikroiglowa')}>Mezoterapia Mikroigłowa Dermapen</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('mezoterapia_mikroiglowa_RF')}>Radiofrekwencja Mikroigłowa (RF Mikroigłowy Frakcyjny)</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('falaRadiowa')}>Fala Radiowa – Nieinwazyjny Termolifting</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('oczyszczanieWodorowe')}>Oczyszczanie Wodorowe</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('skinLift')}>Skin Lift 448kHz - Biolift</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('depilacjaLaserowa')}>Zabiegi Laseroterapii Twarz i Ciało Laser 4D Light Medica</div></a>
          {/*<a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('peelingiKwasowe')}>Kwasy, Monokwasy (peelingi chemiczne)</div></a>*/}
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('kosmetyka_biala')}>Kosmetyka Twarzy / Masaże</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('stylizacjaRzes')}>Stylizacja rzęs</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('stylizacjaBrwiIRzes')}>Henna brwi i rzęs</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('laminacjaBrwiIRzes')}>Laminacja brwi i rzęs</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('depilacjaWoskiemLycon')}>Depilacja woskiem</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('zabiegiNaCialo')}>Zabiegi na ciało</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('pielegnacjaDloniIStop')}>Pielęgnacja dłoni i stóp</div></a>
        </div>
          <div className="list" id="list">
            {activeList.konsultacje && kosmetologia.konsultacje}
            {activeList.mezoterapia_iglowa && kosmetologia.mezoterapia_iglowa}
            {activeList.stymulatory_tkankowe && kosmetologia.stymulatory_tkankowe}
            {activeList.mezoterapia_mikroiglowa && kosmetologia.mezoterapia_mikroiglowa}
            {activeList.mezoterapia_mikroiglowa_RF && kosmetologia.mezoterapia_mikroiglowa_RF}
            {activeList.falaRadiowa && kosmetologia.falaRadiowa}
            {activeList.oczyszczanieWodorowe && kosmetologia.oczyszczanieWodorowe}
            {activeList.skinLift && kosmetologia.skinLift}
            {activeList.depilacjaLaserowa && kosmetologia.depilacjaLaserowa}
            {activeList.peelingiKwasowe && kosmetologia.peelingiKwasowe}
            {activeList.kosmetyka_biala && kosmetologia.kosmetyka_biala}
            {activeList.stylizacjaRzes && kosmetologia.stylizacjaRzes}
            {activeList.stylizacjaBrwiIRzes && kosmetologia.stylizacjaBrwiIRzes}
            {activeList.laminacjaBrwiIRzes && kosmetologia.laminacjaBrwiIRzes}
            {activeList.depilacjaWoskiemLycon && kosmetologia.depilacjaWoskiemLycon}
            {activeList.zabiegiNaCialo && kosmetologia.zabiegiNaCialo}
            {activeList.pielegnacjaDloniIStop && kosmetologia.pielegnacjaDloniIStop}
            <a className="a_booksyBtn" target="_blank" href="https://booksy.com/pl-pl/103284_salon-kosmetyczny-lilien_salon-kosmetyczny_12733_mazancowice"><div className="booksyBtn">Umów wizytę!</div></a>
          </div>
        </div>
      </div>
      </AnimationOnScroll>
    )
}

export default Offer;
