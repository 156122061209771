import React from "react";
import './Home.css';
import NearMeIcon from '@mui/icons-material/NearMe';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const Contact = ()=>{
    return(
        <div className="contact">
            <div className="firstRowContact">
                <div className="location">Starobielska 879,<br/>43-391 Mazańcowice<a target="_blank" href="https://www.google.com/maps/dir//salon+lilien/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4716a166b18a0e8d:0x672399d7398fc697?sa=X&ved=2ahUKEwjOvIH3ufj5AhVG_SoKHSGICsMQ9Rd6BAhWEAU"><div className="locationButton"><NearMeIcon fontSize="medium"/>&nbsp;&nbsp;Zobacz jak dojechać</div></a></div>
                <div className="openHours"><span><AccessTimeIcon/>&nbsp;&nbsp;Godziny otwarcia</span><div className="hours">Poniedziałek - Piątek<br/> 9:00 - 20:00<br/><br/>Sobota<br/>9:00 - 14:00</div></div>
            </div>
            <div className="lineContact"></div>
            <div className="secondRowContact">
                <div className="phoneNumber">+48 668 623 906</div>
                <div className="mediaContact"><a target="_blank" href="https://www.facebook.com/Salon-Kosmetyczny-Lilien-Ewa-Świerczek-166798186754119"><FacebookIcon fontSize="large"/></a><a target="_blank" href="https://www.instagram.com/sk_lilien/"><InstagramIcon fontSize="large"/></a><a href="tel: 668 623 906"><LocalPhoneIcon fontSize="large"/></a></div>
                <div className="emailContact"><a href="mailto:kontakt@salonlilien.pl">kontakt@salonlilien.pl</a></div>
            </div>
        </div>
    )
}

export default Contact