import { useEffect, useState } from 'react';
import './App.css';
import Home from './Home/Home';

function App() {
  const [block, setBlock] = useState(false)

  useEffect(()=>{
    const date = new Date()
    const today = date.getDate()
    if (today >=8) {
     // setBlock(true)
    }
  })

  return (
    <div >
      {block ? <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100vw', height: '100vh'}}><img src='build.png'></img></div>: <Home/>}
    </div>
  );
}

export default App;
