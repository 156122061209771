import React from "react";
import './Home.css';

const Footer = ()=>{

    return(
        <div className="footer">Lilien Ewa Świerczek © Wszystkie prawa zastrzeżone | Designed & Developed by <a className="rights" target="_blank" href="https://it-gaw.pl">&nbsp;it-gaw.pl</a></div>
    )
}

export default Footer;