import React, {useState, useRef} from "react";
import './Home.css';
import Slideshow from './../Slider/Slider';
import Team from "./Team";
import Offer from "./Offer";
import Gallery from "./Gallery";
import Contact from "./Contact";
import Footer from "./Footer";
import './ResponsiveAppBar.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CallIcon from '@mui/icons-material/Call';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "animate.css/animate.min.css";
import AboutUs from './AboutUs';

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

const Home = ()=>{
  const [displayMenu, setDisplayMenu]  = useState(false);
  
  const aboutUsRef = useRef(null);
  const teamRef = useRef(null);
  const offerRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef =useRef(null);
   
  const scrollToAboutUs = () => aboutUsRef.current.scrollIntoView({behavior:"smooth"});
  const scrollToTeam = () => teamRef.current.scrollIntoView({behavior:"smooth"});
  const scrollToOffer = () => offerRef.current.scrollIntoView({behavior:"smooth"});
  const scrollToGallery = () => galleryRef.current.scrollIntoView({behavior:"smooth"});
  const scrollToContact = () => contactRef.current.scrollIntoView({behavior:"smooth"});
    return(
    <div className="background">
      {displayMenu && <div className="mobileMenu">
          <div className="headerMobileMenu">
            <div className="logoMobileMenu"><a href="#"><img src="/images/logo.png"></img></a></div>
            <div className="leaveMenu" onClick={()=> setDisplayMenu(false)}><CloseIcon fontSize="large"/></div>
          </div>
          <div className="mobileNavElements">
          <ul>
              <li onClick={scrollToAboutUs}><a>O nas</a></li>
              <li onClick={scrollToTeam}><a>Nasz zespół</a></li>  
              <li onClick={scrollToOffer}><a>Cennik</a></li> 
              <li onClick={scrollToGallery}><a>Galeria</a></li> 
              <li onClick={scrollToContact}><a>Kontakt</a></li> 
          </ul>
          </div>
        </div>}
       <div className='navbar'>
          <div className="media">
            <div className="media_content">
            <a target="_blank" href="https://www.facebook.com/Salon-Kosmetyczny-Lilien-Ewa-Świerczek-166798186754119"><FacebookIcon/></a>
            </div>
            <div className="media_content">
            <a target="_blank" href="https://www.instagram.com/sk_lilien/"><InstagramIcon/></a>
            </div>
            <div className="media_content">
            <a href="tel: 668 623 906"><CallIcon/></a>
            </div>
          </div>  

          <div className="burger_menu" onClick={()=> setDisplayMenu(true)}><MenuIcon sx={{ fontSize: 60 }}/></div>
          <div className='navbar-links'>
            <ul>
            <li onClick={scrollToAboutUs}><a>O nas</a></li>
            <li onClick={scrollToTeam}><a>Nasz zespół</a></li> 
             <li onClick={scrollToOffer}><a>Cennik</a></li> 
             <li onClick={scrollToGallery}><a>Galeria</a></li> 
             <li onClick={scrollToContact}><a>Kontakt</a></li> 
            </ul>
          </div>
        <a className="btn_a" target="_blank" href="https://booksy.com/pl-pl/103284_salon-kosmetyczny-lilien_salon-kosmetyczny_12733_mazancowice"><div className="btn">Umów wizytę!</div></a>
        </div>
        
        <Slideshow/>
        <div ref={aboutUsRef}>
          <AboutUs/>
        </div>
        <div ref={teamRef}>
          <Team/>
        </div>
        <div ref={offerRef}>
          <Offer/>
        </div>
        <div ref={galleryRef}>
         <Gallery/>
        </div>
        <div ref={contactRef}>
        <Contact/>
        </div>
        <Footer/>
        <div className="logo"><img className="logoImg" src="/images/logo.png"></img></div>
    </div>
    )
}

export default Home;