import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Team =()=>{
    return(
        <AnimationOnScroll animateIn="animate__bounceIn">
        <div className="teamBackground">
            <div className="teamHeader">Nasz Zespół</div>
            <div className="profilExample">
                <div className="teamPortret">
                    <img className="portretimg" src="./avatars/ewa.jpg" alt="portretimg"/>
                </div>
                <div className="teamDescribe"><div className="teamName">Ewa Świerczek</div>Magister Kosmetologii i właścicielka gabinetu. Absolwentka Górnośląskiej Wyższej Szkoły Handlowej w Katowicach na kierunku Kosmetologia Estetyczna. Na kilkunastoletnie doświadczenie w branży beauty  składa się udział w licznych szkoleniach, kursach, warsztatach i konferencjach. Kosmetologia jest dla niej zawodem, ale przede wszystkim pasją życiową i spełnieniem marzeń. Bezgraniczne zaangażowanie w wykonywaną pracę przekłada się na zadowolenie nawet najbardziej wymagających klientów. W pracy kieruje się empatią oraz holistycznym i indywidualnym podejściem do pielęgnacji i zdrowia klienta. Specjalizuje się w zabiegach z zakresu kosmetologii estetycznej, pielęgnacyjnej i leczniczej na twarz i ciało.  Chętnie dzieli się wiedzą praktyczną zdobytą przez lata, prowadzi warsztaty dla kobiet z zakresu prawidłowej pielęgnacji skóry. </div>
            </div>

            <div className="profilExample">
                <div className="teamPortret">
                    <img className="portretimg" src="./avatars/brygida.jpg" alt="portretimg"/>
                </div>
                <div className="teamDescribe"><div className="teamName">Brygida Tyrna</div>Magister kosmetologii, absolwentka Śląskiego Uniwersytetu Medycznego. Specjalizuje się w zabiegach z zakresu kosmetologii pielęgnacyjnej twarzy i ciała. Do każdego klienta podchodzi indywidualnie i holistycznie, dobierając zabiegi w taki sposób, żeby osiągnąć jak najlepsze i najbardziej efektywne rezultaty. Dba również o swoich klientów na codzień, edukując i dobierając prawidłową pielęgnację po zabiegową aby klient odpowiednio troszczył się o swoją skórę w warunkach domowych. Uwielbia pracę z ludźmi, czerpie z tego wiele radości i satysfakcji. Najważniejsze dla niej jest zadowolenie klienta, dlatego nieustannie bierze udział w szkoleniach, warsztatach i konferencjach gdzie zdobywa nową wiedzę i umiejętności z zakresu kosmetologii. 
</div>
            </div>

            <div className="profilExample">
                <div className="teamPortret">
                    <img className="portretimg" src="./avatars/magdalena.jpg" alt="portretimg"/>
                </div>
                <div className="teamDescribe"><div className="teamName">Magdalena Fender</div>Utalentowana i wykwalifikowana stylistka paznokci. Łączy pasję z przyjemnością tworzenia, zna najnowsze trendy i nieustannie dąży do perfekcji. Bierze udział w licznych warsztatach i szkoleniach podczas których podnosi swoje kwalifikacje. Jest ekspertem w dziedzinie pielęgnacji dłoni i stóp. Zadbane dłonie i paznokcie są nieodłączną częścią kobiecości, Magdalena dba o nie z najwyższą starannością.</div>
            </div>
            <div className="profilExample">
                <div className="teamPortret">
                    <img className="portretimg" src="./avatars/justyna.jpg" alt="portretimg"/>
                </div>
                <div className="teamDescribe"><div className="teamName">Justyna Piguła</div>Stylistka paznokci i rzęs. Kocha to co robi i  zaraża swoim entuzjazmem. Stale podnosi swoje kwalifikacje i umiejętności z zakresu pielęgnacji dłoni i stóp oraz stylizacji rzęs. Jest utalentowana i z wielką pasją wykonuje swoją pracę. Systematycznie powiększa swój wachlarz usług do którego zalicza się stylizacja oprawy oka, wykonuje hennę, hennę pudrową oraz laminację brwi i rzęs. Kosmetyka skradła jej serce, postanowiła podnieść swoje kwalifikacje i kształci się w studium kosmetycznym.</div>
            </div>
        </div>
        </AnimationOnScroll>
    )
}

export default Team;