import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GroupsIcon from '@mui/icons-material/Groups';


const AboutUs =()=>{
    return(
        <AnimationOnScroll animateIn="animate__backInLeft">
            <div className="aboutUsHeader">Co nas wyróżnia</div>
        <div className="aboutUsBackground">
    <div className="table">
        <div className="recordInTable2"><VolunteerActivismIcon sx={{ fontSize: 100 }}/><span className="tableHeader">Indywidualne podejście do klienta</span><span className="tableDescribe">Każdego klienta, traktujemy indywidualnie, ponieważ potrzeby każdej osoby są inne i niepowtarzalne -  dlatego aby sprostać oczekiwaniom, nasze specjalistki przeprowadzą konsultację przedzabiegową, aby ustalić indywidualną i spersonalizowaną sesję zabiegową, która będzie odpowiedzią na oczekiwania naszych klientów.</span></div>
        <div className="recordInTable"><GroupsIcon sx={{ fontSize: 100 }}/><span className="tableHeader">Praca z pasją</span><span className="tableDescribe">Wszyscy, którzy odwiedzą Nasz salon, poczują jak wielką pasję i zaangażowanie wkładamy w wykonywanie zabiegów i obsługę klientów. Każde spotkanie z klientem to wyjątkowa chwila, podczas której odkrywamy wewnętrzne piękno, które drzemie w każdym z Nas.</span></div>
        <div className="recordInTable"><AutoAwesomeIcon sx={{ fontSize: 100 }}/><span className="tableHeader">Szeroki wachlarz usług</span><span className="tableDescribe">W ofercie Naszego salonu jest cała gama usług, które są odpowiedzią na najnowsze trendy w branży beauty ale również w odpowiedzi na potrzeby i oczekiwania Naszych klientów. Regularnie poszerzamy swoją ofertę, wprowadzając nowe zabiegi i marki.</span></div>
        <div className="recordInTable2"><VerifiedUserIcon sx={{ fontSize: 100 }}/><span className="tableHeader">Wykorzystywanie innowacyjnych i bezpiecznych technologii.</span><span className="tableDescribe">Najważniejsze jest dla nas bezpieczeństwo Naszych klientów. Wykorzystujemy wyłącznie aparaturę wiodących producentów oraz dermkosmetyki. Preparaty, na ktorych pracujemy oraz które rekomendujemy do pielęgnacji domowej.</span></div>
    </div>
</div>
</AnimationOnScroll>
    )
}

export default AboutUs;
