import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './Slider.css'

const Slideshow = () => {
    const images = [
        "./images/slide_4.jpg",
        "./images/slide_3.jpg",
    ];

    return (
        <Slide>
        <div className="each-slide-effect">
            <div style={{ 'backgroundImage': `url(${images[0]})` }}> 
            </div>
        </div>
        <div className="each-slide-effect">
            <div style={{ 'backgroundImage': `url(${images[1]})` }}>  
            </div>
        </div>
    </Slide>
    );
};

export default Slideshow;