import React from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const CardTemplate = (props)=>{
    return(
        <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height="240"
          image={"./gallery/" + props.path}
          alt="green iguana"
          padding="100"
        />
        </Card>
    )
}
export default CardTemplate;